<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Details d'une boutique</ion-title>
        <ion-buttons slot="end">
      </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" :key="formInfo">

     <ion-item>
        <ion-label>Tout reste a faire</ion-label>
      </ion-item>

   </ion-content>
  </ion-page>
</template>
<script>

//import cuid from 'cuid';

var cuid = require('cuid');

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted} from 'vue'

import { useRoute, useRouter } from 'vue-router';
import shared from "../shared/shared";



export default {
  name: "BoutiqueVoir",
  inject:{shared: "shared"},
setup(props, context){
  //alert(cuid())
  const router = useRouter();
  const route = useRoute();

  const formInfo=reactive({
 });

    shared.localdb.get(route.params.id)
  //shared.create(formInfo)
  .then(function (response) {
  // handle response
  formInfo.value = response
  //alert(JSON.stringify(response))
}).catch(function (err) {
  console.log(err);
});


const sauver = () => {
  //alert('ici');
  //alert (JSON.stringify(formInfo));
  formInfo.type="boutique";
  //je dois sauver
  //alert('la')

  formInfo._id=cuid()+':0';
  //alert(JSON.stringify(formInfo));
  //let a=shared.create(formInfo)

  shared.localdb.put(formInfo)
  //shared.create(formInfo)
  .then(function (response) {
  // handle response
  //alert(response)
}).catch(function (err) {
  console.log(err);
});

};

const takePhoto = async () => {
  //alert('ici')
  let pp =  await shared.takePhot();
  //alert('ici')
  //alert('je reduis')

 formInfo.photo = pp
 //alert(JSON.stringify(formInfo))
}

return {
 //computed
//function
formInfo,

route,
router,
add,
sauver,
takePhoto
}
}
}
</script>

<style scoped>

</style>
